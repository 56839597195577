<template>
  <v-app>
    <v-container class="text-center">
      <v-row>
        <v-col class="pt-md-10"></v-col>
      </v-row>

      <template v-if="resetTokenOk && !success">
        <v-row>
          <v-col>
            <h2>
              <i18n
                :path="
                  invitation
                    ? 'passwordReset.invitationHeader'
                    : 'passwordReset.passwordResetHeader'
                "
              >
                <template #appName>{{ appName }}</template>
              </i18n>
            </h2>
          </v-col>
        </v-row>

        <v-row
          v-if="attemptFinished && error && passwordError === true"
          justify="center"
        >
          <v-alert type="error">
            {{ $t("passwordReset." + error) }}
          </v-alert>
        </v-row>

        <v-row>
          <v-col>
            <i18n
              tag="div"
              :path="
                invitation
                  ? 'passwordReset.invitationInfo'
                  : 'passwordReset.passwordResetInfo'
              "
            >
              <template #appName>{{ appName }}</template>
            </i18n>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="auto">
            <v-text-field
              v-model="password"
              :label="$t('passwordReset.newPassword')"
              :rules="[passwordError, rules.required, rules.min]"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="auto">
            <v-btn
              v-text="
                invitation
                  ? $t('passwordReset.register')
                  : $t('passwordReset.reset')
              "
              color="primary"
              class="mt-2"
              @click="resetPassword()"
              :disabled="!valid"
            >
            </v-btn>
          </v-col>
        </v-row>
      </template>

      <v-row v-else-if="success" justify="center">
        <v-alert type="success">
          <i18n
            :path="
              invitation
                ? 'passwordReset.invitationSuccess'
                : 'passwordReset.passwordResetSuccess'
            "
            tag="span"
          >
            <template #here>
              <router-link
                :to="{ name: 'Home' }"
                v-text="$t('passwordReset.here')"
                class="font-weight-black white--text"
              >
              </router-link>
            </template>
          </i18n>
        </v-alert>
      </v-row>

      <v-row v-else justify="center">
        <!-- the token data is not OK -->
        <v-alert type="error">
          {{ $t("badData") }}
        </v-alert>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import axios from "axios";
import FormValidationMixin from "@/components/mixins/FormValidationMixin";
import { mapActions } from "vuex";

export default {
  name: "PasswordResetPage",

  mixins: [FormValidationMixin],

  data() {
    return {
      invitation: this.$route.meta.invitation,
      uid: this.$route.query.uid,
      token: this.$route.query.token,
      error: null,
      errorObj: null,
      password: "",
      showPassword: false,
      success: false,
      attemptFinished: false, // was attempt to reset made?
      appName: "Contacts Realm",
      email: null,
    };
  },

  computed: {
    passwordError() {
      if (this.errorObj && !this.passwordEdited) {
        if (
          this.errorObj.response.data &&
          this.errorObj.response.data.new_password2
        ) {
          return this.errorObj.response.data.new_password2[0];
        }
      }
      return true;
    },
    valid() {
      return this.password && this.password.length >= 8;
    },
    resetTokenOk() {
      return this.uid && this.token;
    },
  },

  methods: {
    ...mapActions(["login"]),
    async resetPassword() {
      this.errorObj = null;
      this.error = null;
      try {
        let resp = await axios.post(
          `/api/user/password-reset`,
          {
            uid: this.uid,
            token: this.token,
            new_password1: this.password,
            new_password2: this.password,
          },
          { privileged: true }
        );
        this.success = true;
        this.email = resp.data?.email;
        if (this.email) {
          // we can use this to login immediately
          await this.login({ email: this.email, password: this.password });
          await this.$router.replace({ name: "Home" });
        }
      } catch (error) {
        this.errorObj = error;
        this.error = "someError";
      } finally {
        this.attemptFinished = true;
      }
    },
  },
};
</script>
