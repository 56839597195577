var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('v-container',{staticClass:"text-center"},[_c('v-row',[_c('v-col',{staticClass:"pt-md-10"})],1),(_vm.resetTokenOk && !_vm.success)?[_c('v-row',[_c('v-col',[_c('h2',[_c('i18n',{attrs:{"path":_vm.invitation
                  ? 'passwordReset.invitationHeader'
                  : 'passwordReset.passwordResetHeader'},scopedSlots:_vm._u([{key:"appName",fn:function(){return [_vm._v(_vm._s(_vm.appName))]},proxy:true}],null,false,2883930320)})],1)])],1),(_vm.attemptFinished && _vm.error && _vm.passwordError === true)?_c('v-row',{attrs:{"justify":"center"}},[_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.$t("passwordReset." + _vm.error))+" ")])],1):_vm._e(),_c('v-row',[_c('v-col',[_c('i18n',{attrs:{"tag":"div","path":_vm.invitation
                ? 'passwordReset.invitationInfo'
                : 'passwordReset.passwordResetInfo'},scopedSlots:_vm._u([{key:"appName",fn:function(){return [_vm._v(_vm._s(_vm.appName))]},proxy:true}],null,false,2883930320)})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-text-field',{attrs:{"label":_vm.$t('passwordReset.newPassword'),"rules":[_vm.passwordError, _vm.rules.required, _vm.rules.min],"type":_vm.showPassword ? 'text' : 'password',"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"outlined":""},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"mt-2",attrs:{"color":"primary","disabled":!_vm.valid},domProps:{"textContent":_vm._s(
              _vm.invitation
                ? _vm.$t('passwordReset.register')
                : _vm.$t('passwordReset.reset')
            )},on:{"click":function($event){return _vm.resetPassword()}}})],1)],1)]:(_vm.success)?_c('v-row',{attrs:{"justify":"center"}},[_c('v-alert',{attrs:{"type":"success"}},[_c('i18n',{attrs:{"path":_vm.invitation
              ? 'passwordReset.invitationSuccess'
              : 'passwordReset.passwordResetSuccess',"tag":"span"},scopedSlots:_vm._u([{key:"here",fn:function(){return [_c('router-link',{staticClass:"font-weight-black white--text",attrs:{"to":{ name: 'Home' }},domProps:{"textContent":_vm._s(_vm.$t('passwordReset.here'))}})]},proxy:true}])})],1)],1):_c('v-row',{attrs:{"justify":"center"}},[_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.$t("badData"))+" ")])],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }